.logotype {
    width: 140px;
    background: transparent url("../img/amoniac_logo.svg") center center no-repeat;
    background-size: contain;

    &::before {
        content: "";
        display: block;
        padding-top: 45.7142857%;
    }
}
