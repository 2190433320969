.number-list-card {
    padding: 34px 34px;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @include media-breakpoint-down(lg) {
        padding: 20px;
    }

    &__number {
        align-self: center;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 20px;
        min-width: 40px;
        color: $primary;
        font-weight: 700;

        @include media-breakpoint-down(lg) {
            align-self: flex-start;
            margin-right: 10px;
            min-width: 30px;
        }
    }

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
    }
}
