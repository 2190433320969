.footer-section {
    padding-top: 100px;
    padding-bottom: 100px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background: linear-gradient(77.63deg, #def3ff 10.94%, #ffe0de 96.33%);

    @include media-breakpoint-down(md) {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 30px;
    }

    &--green {
        background: linear-gradient(76.76deg, #e2e6e8 1.43%, #ebffe1 96.3%);
    }
}
