.slider {
    --acent-color: #{$brand-ruby};

    &--blue {
        --acent-color: #{$brand-react};
    }

    &--green {
        --acent-color: #{$brand-vue};
    }

    position: relative;
    margin: 0 -200px;
    user-select: none;
    padding-top: 80px;

    @include media-breakpoint-down(md) {
        padding-top: 0px;
        padding-bottom: 70px;
    }

    & .tns-nav,
    & [data-action="stop"],
    & [data-action="start"] {
        display: none;
    }

    & .tns-nav {
        position: absolute;
        bottom: 19px;
        left: 200px;

        & > button {
            display: block;
            padding: 3px;
            background: transparent;
            border: 0px solid transparent;

            &:before {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 10px;
                background: $g-900;
                transition: all 230ms ease-out;
            }

            &.tns-nav-active:before {
                background: var(--acent-color);
                width: 24px;
            }
        }

        @include media-breakpoint-down(md) {
            display: flex;
        }
    }

    & .tns-ovh {
        margin-top: -30px;
        margin-bottom: -30px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    & .tns-slider,
    & .tns-item {
        display: flex !important;
        align-items: stretch !important;
    }

    & .tns-controls {
        position: absolute;
        top: 0;
        right: 200px;
        display: flex;

        @include media-breakpoint-down(md) {
            top: initial;
            bottom: 0;
        }

        & > * {
            width: 52px;
            height: 52px;
            font-size: 0;
            border: 1px solid var(--acent-color);
            border-radius: 50%;
            background: transparent;
            transition: all 230ms ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                height: 2px;
                width: 9px;
                background: var(--acent-color);
                border-radius: 2px;
                transition: all 230ms ease-out;
            }

            &:hover {
                background-color: var(--acent-color);
            }

            &:hover:before,
            &:hover:after {
                background: white;
            }

            &[disabled] {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        & > [data-controls="prev"]:before {
            transform-origin: 1px 1px;
            transform: rotate(-45deg) translate(0, -0.5px);
        }

        & > [data-controls="prev"]:after {
            transform-origin: 1px 1px;
            transform: rotate(45deg) translate(0, 0.5px);
        }

        & > [data-controls="next"]:before {
            transform-origin: 8px 1px;
            transform: rotate(-45deg) translate(0, 0.5px);
        }

        & > [data-controls="next"]:after {
            transform-origin: 8px 1px;
            transform: rotate(45deg) translate(0, -0.5px);
        }

        & > * + * {
            margin-left: 10px;
        }
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 200px;
        z-index: 1;
        pointer-events: none;
    }

    &:before {
        left: 0;
        background: linear-gradient(90deg, white, transparent);
    }

    &:after {
        right: 0;
        background: linear-gradient(90deg, transparent, white);
    }
}
