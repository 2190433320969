.form-control {
    display: block;
    width: 100%;
    min-height: 52px;
    border-radius: 30px;
    border: 1px solid white;
    background: rgba(white, 0.5);
    padding: 0 20px;
    font-family: Poppins;
    font-size: 18px;
    box-shadow: 0 0 0 0 rgba($primary, 0.5);
    transition: box-shadow 230ms ease-out, border 230ms ease-out;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 4px rgba($primary, 0.5);
        border-color: $primary;
    }
}

textarea.form-control {
    min-width: 100%;
    max-width: 100%;
    max-height: 300px;
    padding: 16px 20px;
    resize: none;
}
