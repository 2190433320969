.icon-box {
    width: 62px;
    height: 62px;
    border-radius: 15px;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;

    &--orange,
    &--brand-ruby {
        border-color: $brand-ruby;
    }

    &--blue,
    &--brand-react {
        border-color: $brand-react;
    }

    &--green,
    &--brand-vue {
        border-color: $brand-vue;
    }
}
