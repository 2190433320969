* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: white;
    font-family: $font-stack;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
    color: $gb-300;
}
