$v-spacing: 0.2em;
$h-spacing: 0.4em;

.title-mark {
    position: relative;
    padding: $v-spacing $h-spacing;
    margin: calc($v-spacing * -1) calc($h-spacing * -1);

    &--brand-ruby path {
        fill: $brand-ruby;
    }

    &--brand-react path {
        fill: $brand-react;
    }

    &--brand-vue path {
        fill: $brand-vue;
    }

    &__label {
        position: relative;
        color: white;
    }

    &__svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
