$font-stack: Poppins, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;

$font-weights: (300, 400, 500, 600, 700);

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

$breakpoints-map: (
    xs: $breakpoint-xs,
    sm: $breakpoint-sm,
    md: $breakpoint-md,
    lg: $breakpoint-lg,
    xl: $breakpoint-xl,
    xxl: $breakpoint-xxl,
);

$g-100: #1a1a1a;
$g-200: #333333;
$g-300: #4d4d4d;
$g-400: #666666;
$g-500: #808080;
$g-600: #999999;
$g-700: #b3b3b3;
$g-800: #cccccc;
$g-900: #e6e6e6;
$g-990: #fafafa;

$gb-200: #2e363a;
$gb-300: #43535d;
$gb-400: #697580;

$primary: #0f82e6;

$brand-amoniac: #76c4ee;
$brand-ruby: #fb4550;
$brand-react: #61dafb;
$brand-vue: #41b883;

$colors-map: (
    primary: $primary,
    brand-amoniac: $brand-amoniac,
    brand-ruby: $brand-ruby,
    brand-react: $brand-react,
    brand-vue: $brand-vue,
);

$spacing-map: (5, 10, 20, 30, 40, 50, 60, 70, 80);
