@import "~tiny-slider/src/tiny-slider.scss";
@import "./variables.scss";
@import "./utils.scss";

@import "../fonts/Poppins/stylesheet.css";
@import "../fonts/Prometo/stylesheet.css";

@import "./reset.scss";
@import "./grid.scss";
@import "./spacing.scss";
@import "./typography.scss";
@import "./flex-utils.scss";

/**
 * Components
 */
@import "./components/container.scss";
@import "./components/button.scss";
@import "./components/logotype.scss";
@import "./components/title-mark.scss";
@import "./components/tech-illustration.scss";
@import "./components/card.scss";
@import "./components/list-card.scss";
@import "./components/number-list-card.scss";
@import "./components/icon-box.scss";
@import "./components/slider.scss";
@import "./components/form-controls.scss";
@import "./components/testimonial-card.scss";

/**
 * Sections
 */
@import "./sections/first-screen-section.scss";
@import "./sections/what-we-offer-section.scss";
@import "./sections/our-cases-section.scss";
@import "./sections/advantages-section.scss";
@import "./sections/letter-box-section.scss";
@import "./sections/testimonials-section.scss";
@import "./sections/footer-section.scss";

.overflow-hidden {
    overflow: hidden;
}

.position-relative {
    position: relative;
}

.w-100p {
    width: 100%;
}

@each $breakpoint in map-keys($breakpoints-map) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-map);

    @include media-breakpoint-up($breakpoint, $breakpoints-map) {
        .d#{$infix}-none {
            display: none;
        }

        .d#{$infix}-block {
            display: block;
        }

        .d#{$infix}-flex {
            display: flex;
        }
    }
}

.bg-g-990 {
    background: $g-990;
}
