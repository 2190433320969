.advantages-section {
    &__second {
        background: $g-990;
        padding-top: 140px;
        padding-bottom: 360px;

        @include media-breakpoint-down(md) {
            padding-top: 0px;
            background: transparent;
        }
    }

    &__container {
        margin-top: -160px;
    }

    &__first-card {
        margin-top: 200px;
    }

    &__card {
        background: $g-990;
        border-radius: 40px;
        padding: 40px;

        @include media-breakpoint-down(md) {
            padding: 15px;
            border-radius: 20px;
        }
    }

    &__card-arrow {
        flex-shrink: 0;
        display: block;
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
        margin-left: 10px;
        background: transparent url("~@/img/arrow-left-pink.svg") center center no-repeat;
        transition: all 230ms;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__card.is-open &__card-arrow {
        transform: rotate(270deg);
    }

    &__card-header {
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        & h3 {
            flex-grow: 1;
            flex-shrink: 1;

            @include media-breakpoint-down(md) {
                max-width: none !important;
                font-weight: 500;
            }
        }

        & .icon-box {
            flex-shrink: 0;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0px;
                margin-right: 10px;
                width: 50px;
                height: 50px;
            }
        }
    }

    &__card-body {
        padding-top: 10px;

        @include media-breakpoint-down(md) {
            max-width: none !important;
            padding-top: 20px;
        }
    }

    &__card-image {
        width: 100%;
        border-radius: 40px;
        background: linear-gradient(151.96deg, #ffa78f 21.56%, #e61521 130.38%);

        @include media-breakpoint-down(md) {
            border-radius: 20px;
        }

        &--blue {
            background: linear-gradient(230.92deg, #39affd -0.23%, #477fff 100.3%);
        }

        &--green {
            background: linear-gradient(220.53deg, #9bf763 0%, #26ab5b 76.95%);
        }

        &::before {
            content: "";
            display: block;
            padding-top: 51.333333%;
            background: transparent url("~@/img/data.png") 1px center no-repeat;
            background-size: cover;
            border-radius: 38px;

            @include media-breakpoint-down(md) {
                border-radius: 18px;
            }
        }
    }

    &__slider {
        margin-top: -60px;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }

        &:before {
            background: linear-gradient(90deg, $g-990, transparent);
        }

        &:after {
            background: linear-gradient(90deg, transparent, $g-990);
        }
    }

    &__slider-card {
        border-radius: 40px;
        padding: 40px;
        background: white;

        @include media-breakpoint-down(md) {
            padding: 20px;
            border-radius: 20px;
            background: $g-990;
        }
    }

    &__diamond {
        position: absolute;
        pointer-events: none;
        top: -80px;
        right: 10%;
        width: 400px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__development-cycles {
        position: absolute;
        pointer-events: none;
        top: -80px;
        right: 10%;
        width: 552px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
