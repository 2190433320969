$flex-align-map: (flex-start, center, flex-end, baseline, stretch);
$flex-justify-map: (start, center, end, space-between, space-around, space-evenly);
$flex-direction-map: (row, row-reverse, column, column-reverse);

.d-flex {
    display: flex;
}

@each $breakpoint in map-keys($breakpoints-map) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-map);
    @include media-breakpoint-up($breakpoint, $breakpoints-map) {
        .flex-grow#{$infix}-1 {
            flex-grow: 1;
        }

        .flex-grow#{$infix}-0 {
            flex-grow: 0;
        }

        .flex-shrink#{$infix}-1 {
            flex-shrink: 1;
        }

        .flex-shrink#{$infix}-0 {
            flex-shrink: 0;
        }

        @each $value in $flex-direction-map {
            .flex-direction#{$infix}-#{$value} {
                flex-direction: $value;
            }
        }

        @each $value in $flex-align-map {
            .align-self#{$infix}-#{$value} {
                align-self: $value !important;
            }
        }

        @each $value in $flex-align-map {
            .align-items#{$infix}-#{$value} {
                align-items: $value;
            }
        }

        @each $value in $flex-justify-map {
            .justify-content#{$infix}-#{$value} {
                justify-content: $value;
            }
        }
    }
}
