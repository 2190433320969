.testimonial-card {
    width: 100%;
    display: block;
    color: inherit;
    text-decoration: inherit;
    padding: 1.2rem;
    border-radius: 40px;
    box-shadow: 0 0 30px rgba(110, 154, 205, 0.1), 10px 10px 20px rgba(110, 154, 205, 0.15);
    transition: all ease-in-out 130ms;

    &__body &__spacer {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__spacer {
        width: 4rem;
        flex-shrink: 0;
    }

    &__value {
        flex-shrink: 1;
        flex-grow: 1;
    }

    &__body {
        margin-top: 1rem;
    }

    &__review-details {
        align-items: center;
        display: flex;
        gap: 1rem;
        font-size: 1rem;
        margin-top: 0.5rem;
        line-height: 1;
    }

    &__date {
        color: #818d94;
    }

    &__avatar {
        border-radius: 50%;
        width: 3rem;
        background-color: #eee;
        background-size: cover;
        background-position: center center;

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    &__author-name {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1;
    }

    &__text {
        font-size: 1rem;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }

        & br {
            content: "";
            margin: 2em;
            display: block;
            font-size: 24%;
        }
    }

    &__rate {
        display: flex;
        gap: 0.15rem;

        & > span {
            width: 1rem;
            height: 1rem;
            background: url("../img/ic_star_rate_14.png");
            background-size: contain;
            background-position: center center;
        }
    }

    &__reply {
        margin-top: 2rem;
        padding: 0.5rem 0 0.5rem 1rem;
        border-left: 2px solid var(--acent-color);
    }

    &__reply-header {
        display: flex;
        gap: 1rem;
        font-size: 1rem;
    }

    &__reply-author {
        font-weight: 500;
    }

    &__reply-text {
        margin-top: 0.5rem;
    }

    & [data-text-collapsed="true"] {
        position: relative;
        max-height: 80px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            max-height: 140px;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 40px;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }

    &__read-more {
        margin-top: 1rem;
        font-size: 1.1rem;
        cursor: pointer;
        color: var(--acent-color);

        &:hover {
            text-decoration: underline;
        }
    }
}
