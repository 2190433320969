.testimonials-section {
    padding-top: 40px;
    padding-bottom: 100px;

    &__slider-container {
        margin-top: -50px;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }
}
