body {
    font-size: 21px;

    @include media-breakpoint-down(lg) {
        font-size: 18px;
    }
}

.text-dispaly,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-family: Prometo;
    color: $gb-200;
}

h1,
.h1 {
    font-family: Prometo;
    font-size: 58px;
    line-height: 72px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 52px;
    }
}

h2,
.h2 {
    font-family: Prometo;
    font-size: 28px;
    line-height: 32px;
}

h3,
.h3 {
    font-family: Prometo;
    font-size: 24px;
    line-height: 28px;

    @include media-breakpoint-down(md) {
        font-size: 21px;
        line-height: 24px;
    }
}

h4,
.h4 {
    font-family: Prometo;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 24px;
    }
}

h5,
.h5 {
    font-family: Prometo;
    font-size: 21px;
    font-weight: 500;
}

.text-dispaly {
    font-size: 58px;
    line-height: 72px;
}

@each $name, $color in $colors-map {
    .text-#{$name} {
        color: $color;
    }
}

@each $breakpoint in map-keys($breakpoints-map) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-map);

    @include media-breakpoint-up($breakpoint, $breakpoints-map) {
        @each $font-weight in $font-weights {
            .font#{$infix}-#{$font-weight} {
                font-weight: $font-weight;
            }
        }

        .p#{$infix}-21 {
            font-size: 21px;
        }

        .p#{$infix}-22 {
            font-size: 22px;
        }

        .text#{$infix}-center {
            text-align: center;
        }

        .text#{$infix}-left {
            text-align: left;
        }

        .text#{$infix}-right {
            text-align: right;
        }
    }
}
