.first-screen-section {
    &__header {
        padding-top: 50px;

        @include media-breakpoint-down(md) {
            & .logotype {
                width: 100px;
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__body {
        width: 640px;
        padding-right: 20px;

        @include media-breakpoint-down(lg) {
            width: 400px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-bottom: 0;
        }
    }

    &__illustration {
        flex-grow: 1;
        flex-shrink: 1;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 20px;
        }
    }
}
