.what-we-offer-section {
    &__illustration {
        position: relative;
        width: 100%;

        &:before {
            content: "";
            display: block;
            padding-top: 67%;
        }

        &-image {
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 120%;
            background: url("~@/img/backend_frontend_ruby.png") center center no-repeat;
            background-size: contain;

            &:before {
                content: "";
                display: block;
                padding-top: 81%;
            }
        }
    }
}
