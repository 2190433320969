$container-padding: 30px;
$container-padding-sm: 15px;

.container {
    --container-padding: #{$container-padding};
    width: 100%;
    max-width: calc(1468px + (var(--container-padding) * 2));
    padding: 0 var(--container-padding);
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        --container-padding: #{$container-padding-sm};
    }

    &--sm {
        max-width: calc(1220px + (var(--container-padding) * 2));
    }

    &--lg {
        max-width: calc(1524px + (var(--container-padding) * 2));
    }
}
