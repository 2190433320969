.btn {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 56px;
    padding: 12px 30px;
    font-size: 24px;
    line-height: 32px;
    background: $primary;
    color: white;
    cursor: pointer;
    border-radius: 52px;
    font-weight: 500;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    user-select: none;
    transition: all 100ms ease-out;
    border: 0px solid transparent;

    @include media-breakpoint-down(md) {
        & {
            min-height: 52px;
            padding: 12px 20px;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &--block {
        display: flex;
        width: 100%;
    }

    &:hover {
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0px);
    }

    &--orange-g {
        background: linear-gradient(151.96deg, #ffa78f 21.56%, #e61521 130.38%);
        &:hover {
            box-shadow: 0 0.5em 0.5em -0.5em #e61521, 0 1em 1.5em -1em #e61521;
        }
    }

    &--blue-g {
        background: linear-gradient(230.92deg, #39affd -0.23%, #477fff 100.3%);
        &:hover {
            box-shadow: 0 0.5em 0.5em -0.5em #477fff, 0 1em 1.5em -1em #477fff;
        }
    }

    &--green-g {
        background: linear-gradient(220.53deg, #9bf763 0%, #26ab5b 76.95%);
        &:hover {
            box-shadow: 0 0.5em 0.5em -0.5em #26ab5b, 0 1em 1.5em -1em #26ab5b;
        }
    }

    &-arrow {
        width: 1em;
        height: 1em;
        display: inline-block;
        background: transparent url("../img/li_arrow-down.svg") center center no-repeat;
        background-size: contain;
        margin-left: 0.5em;
    }
}
