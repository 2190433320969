@each $breakpoint in map-keys($breakpoints-map) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-map);
    @include media-breakpoint-up($breakpoint, $breakpoints-map) {
        @each $spacing in $spacing-map {
            $s: $spacing * 1px;

            .m#{$infix}-#{$spacing} {
                margin: $s;
            }

            .mt#{$infix}-#{$spacing} {
                margin-top: $s;
            }

            .mb#{$infix}-#{$spacing} {
                margin-bottom: $s;
            }

            .ml#{$infix}-#{$spacing} {
                margin-left: $s;
            }

            .mr#{$infix}-#{$spacing} {
                margin-right: $s;
            }

            .my#{$infix}-#{$spacing} {
                margin-top: $s;
                margin-bottom: $s;
            }

            .mx#{$infix}-#{$spacing} {
                margin-left: $s;
                margin-right: $s;
            }

            .p#{$infix}-#{$spacing} {
                padding: $s;
            }

            .pt#{$infix}-#{$spacing} {
                padding-top: $s;
            }

            .pb#{$infix}-#{$spacing} {
                padding-bottom: $s;
            }

            .pl#{$infix}-#{$spacing} {
                padding-left: $s;
            }

            .pr#{$infix}-#{$spacing} {
                padding-right: $s;
            }

            .py#{$infix}-#{$spacing} {
                padding-top: $s;
                padding-bottom: $s;
            }

            .px#{$infix}-#{$spacing} {
                padding-left: $s;
                padding-right: $s;
            }
        }
    }
}
