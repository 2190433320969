.list-card {
    padding: 20px 30px;
    display: flex;
    align-items: stretch;

    @include media-breakpoint-down(md) {
        padding: 20px;
        flex-direction: column;
    }

    &__icon {
        align-self: center;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 30px;

        @include media-breakpoint-down(md) {
            align-self: flex-start;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
    }
}
