.letter-box-section {
    margin-top: -260px;
}

.letter-box {
    padding: 40px;
    border-radius: 40px;
    background: linear-gradient(77.63deg, #def3ff 10.94%, #ffe0de 96.33%);

    @include media-breakpoint-down(md) {
        padding: 20px;
        border-radius: 20px;
    }

    &--green {
        background: linear-gradient(76.76deg, #e2e6e8 1.43%, #ebffe1 96.3%);
    }
}
