.our-cases {
    &__carousel {
        & .carousel-navigation {
            margin: 0 -30px;
            padding: 0 30px;
            overflow-x: scroll;

            @include media-breakpoint-down(md) {
                margin: 0 -15px;
                padding: 0 15px;
            }
        }

        & .carousel-navigation__container {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            min-width: max-content;
        }

        & .carousel-navigation__item {
            padding: 10px 15px;
            font-size: 24px;
            line-height: 28px;
            color: $gb-400;
            font-weight: 400;
            cursor: pointer;
            position: relative;
            flex-shrink: 0;
            white-space: nowrap;
            transition: all 230ms ease-out;

            @include media-breakpoint-down(md) {
                font-size: inherit;
            }

            &::before {
                transition: all 230ms ease-out;
                content: "";
                position: absolute;
                bottom: 0;
                left: 20%;
                right: 20%;
                height: 4px;
                background: $brand-ruby;
                border-radius: 3px;
                opacity: 0;
            }

            &.active {
                color: $brand-ruby;
                font-weight: 700;

                &::before {
                    opacity: 1;
                    left: 10px;
                    right: 10px;
                }
            }
        }

        .carousel-navigation--blue .carousel-navigation__item {
            &::before {
                background: $brand-react;
            }

            &.active {
                color: $brand-react;
            }
        }

        .carousel-navigation--green .carousel-navigation__item {
            &::before {
                background: $brand-vue;
            }

            &.active {
                color: $brand-vue;
            }
        }

        & .carousel-slides {
            position: relative;
            transition: all 230ms ease-in-out;
            overflow: hidden;

            & > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: all 230ms ease-in-out;
                opacity: 0;
                z-index: 0;
            }

            & > .active {
                opacity: 1;
                z-index: 1;
            }
        }

        & .carousel-slide {
            padding: 30px;

            @include media-breakpoint-down(md) {
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    &__carousel-image {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 30px rgba(110, 154, 205, 0.1), 10px 10px 20px rgba(110, 154, 205, 0.15);
    }
}
