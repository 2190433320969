$grid-gutter-map: (
    10: 10px,
    20: 20px,
    30: 30px,
);

.row {
    $grid-half-gutter: calc(20px / 2);

    --grid-gutter: #{$grid-half-gutter};
    display: flex;
    flex-wrap: wrap;
    margin: calc(var(--grid-gutter) * -1);
}

@each $breakpoint in map-keys($breakpoints-map) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-map);
    @each $key, $value in $grid-gutter-map {
        $grid-half-gutter: calc($value / 2);
        @include media-breakpoint-up($breakpoint, $breakpoints-map) {
            .row#{$infix}-#{$key} {
                --grid-gutter: #{$grid-half-gutter};
            }
        }
    }
}

.col {
    padding: var(--grid-gutter);
}

@each $breakpoint in map-keys($breakpoints-map) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-map);
    @for $n from 1 through 12 {
        $col-width: calc(100% / 12) * $n;
        @include media-breakpoint-up($breakpoint, $breakpoints-map) {
            .col#{$infix}-#{$n} {
                width: $col-width;
            }
        }
    }
}
