.tech-illustration {
    position: relative;

    &__box {
        padding-top: 87.6%;
        border-radius: 40px;
        background: #eee;
    }

    &__ellipse {
        pointer-events: none;
        position: absolute;
        width: 70%;
        background: transparent url("../img/ellipse_blue.svg") center center no-repeat;
        background-size: contain;
        transform: translate(-50%, -50%);

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        &--blue {
            top: 73%;
            left: 24%;
        }

        &--pink {
            background-image: url("../img/ellipse_pink.svg");
            top: 30%;
            left: 82%;
        }

        &--green {
            background-image: url("../img/ellipse_green.svg");
            top: 30%;
            left: 82%;
        }

        &--gray {
            background-image: url("../img/ellipse_gray.svg");
            top: 73%;
            left: 24%;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 40px;
    }

    /**
     * Ruby on Rails variant
     */
    &--ror &__box,
    &--react &__box,
    &--react-native &__box {
        background: linear-gradient(77.53deg, #def3ff 5.29%, #ffe0de 96.33%);
    }

    &--vue &__box {
        background: linear-gradient(76.76deg, #e2e6e8 1.43%, #ebffe1 96.3%);
    }

    &--ror &__image {
        background: transparent url("../img/tech_illustration_ror.svg") center center no-repeat;
        background-size: 94% auto;
        background-position: 100% 100%;
    }

    &--react &__image {
        background: transparent url("../img/react/tech_illustration.png") center center no-repeat;
        background-size: 100% auto;
        background-position: 100% 100%;
    }

    &--react-native &__image {
        background: transparent url("../img/react/tablet_phone.png") center center no-repeat;
        background-size: 100% auto;
        background-position: 100% 100%;
    }

    &--vue &__image {
        background: transparent url("../img/vue/tech_illustration.png") center center no-repeat;
        background-size: 100% auto;
        background-position: 100% 100%;
    }
}
